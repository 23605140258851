import React from "react";
import App from "./src/App";

/**
 * Landing Page Comopnent
 */

const Landing = () => {
  return <App />;
};

export default Landing;
